import {LocaleDataModel} from '@web/core/models/local/locale.model'
import test from './test/kk'

const locale: LocaleDataModel = {
  title: 'Апта сайынғы сынақ',
  start_test: 'Бастау',
  continue_test: 'Тестті жалғастыру',
  my_test_results: 'Нәтиже',
  test_results: 'Жалпы рейтинг',
  main: {
    title: 'Апталық тест',
    my_tests: 'Менің тесттерім',
    description: 'Әр апта тестті тапсырып, аптаның соңында нәтижеңізді оқушылар рейтингісінен көріңіз',
    hint: 'Тестті аптасына бір рет өтуге болады',
    not_found: 'Апталық тест әзірге дайын емес',
    not_passed: {
      title: 'Сіз осы аптада тестті тапсырған жоқсыз',
      countdown: 'Келесі тест басталады:',
    },
    not_started: {
      start: 'Бастау',
      countdown: 'Тестті әлі тапсыруға болады:',
    },
    started: {
      continue: 'Жалғастыру',
      countdown: 'Тест аяқталатын уақыт:',
    },
    finished: {
      see_results: 'Нәтижемді көру',
      test_history: 'Сынақ тарихы',
      countdown: 'Жалпы рейтинг шығатын уақыт:',
    },
    has_results: {
      title: 'Апта қорытындысы!',
      list_header: 'Рейтинг',
      place: 'Орын',
      points: '{{points}} балл • {{time}} минут',
      rating_points: '{{place}} орын • {{points}} балл',
    },
    start_modal: {
      title: 'Тестті бастау',
      message: 'Барлық тестке 4 сағат беріледі. Осы уақыт аралығында сіз тестті аяқтауыңыз керек. Осы 4 сағат ішінде кез-келген уақытта тесттен шығып, қайта кіріп жалғастыра аласыз.',
      cancel_text: 'Болдырмау',
      confirm_text: 'Тестті бастау',
    },
    rating_placeholder: 'Апта сайынғы тестілеуден өтіңіз, сонда сіз апталық тест нәтижелері бойынша оқушылардың рейтингін көре аласыз',
    empty_rating: 'Тізім бос',
    all_subjects: 'Барлық пәндер',
    filter: {
      select_first_subject: 'Бірінші пәнді таңдаңыз',
      select_second_subject: 'Екінші пәнді таңдаңыз',
    },
  },
  configure: {
    title: 'Пәндерді таңдаңыз',
    select_subject: 'Пәнді таңдаңыз',
    leave_empty: 'Бос қалдыру',
    select_language: 'Тілді таңдаңыз',
    go_back: 'Артқа оралу',
    start_test: 'Тестті бастау',
    required_subject: 'Міндетті пән',
    cannot_select_subject_modal: {
      title: 'Назар аударыңыз',
      message: 'Алдымен бірінші пәнді таңдаңыз.',
    },
  },
  history: {
    title: 'Менің тесттерім',
    empty_list: 'Сіз тапсырған апталық тестілер тізімі осы жерде көрсетіледі',
    points: '{{points}} ұпай',
    go_back: 'Артқа оралу',
    graph_header: 'Соңғы сынақ кестесі',
  },
  subject: {
    title: 'Пәндер',
    title_countdown: 'Пәндер ({{countdown}})',
    go_back: 'Артқа оралу',
    finish_test: 'Тестті аяқтау',
    finish_test_modal: {
      title: 'Тестті аяқтау',
      message: 'Тестті аяқтағыңыз келетініне сенімдісіз бе? Тестті аяқтаған соң жауаптарыңызды өзгерте алмайсыз.',
      cancel_text: 'Болдырмау',
      confirm_text: 'Тестті аяқтау',
    },
    quit: 'Шығу',
    back: 'Артқа',
    next: 'Келесі',
    waiting_test_generation: 'Күте тұрыңыз, сіздің тест дайындалуда...',
  },
  results: {
    result_page: 'Нәтиже парақшасы',
    weekly_overall: 'Апта қорытындысы',
    title: 'Сіздің нәтижеңіз',
    go_back: 'Артқа оралу',
    see_errors: 'Қатемен жұмыс',
    your_place: 'Сіздің позицияңыз:',
    place: '{{place}} орын',
    in_overall_rating: 'жалпы рейтингте',
    grants_hint: '2021 жылы <span class="text-orange">2432 грант</span> бөлінді',
    analytics_best_themes: 'Сіз жақсы<br>білетін тақырыптар',
    analytics_worst_themes: 'Сізге дайындалу<br>керек тақырыптар',
    want_more_edu: 'Нәтижемді жақсартқым келеді',
  },
  rating: {
    header: 'Апталық Рейтинг',
    filter: 'Барлық пәндер',
    points: '{{point}} балл',
    filter_header: 'Пәнді таңдаңыз',
    filter_submit: 'Ок',
    filter_cancel: 'Артқа',
    total_rating: 'Жалпы рейтинг',
    my_friends: 'Менің достарым',
    app_banner: 'Сіздің сыныбыңыздан тағы кім тест тапсырғанын көргіңіз келе ме?<br>Joo.kz қосымшасын жүктеп алыңыз да, контактілеріңізбен бөлісіңіз',
  },
  analysis: {
    title: 'Сіздің нәтижеңіз',
    place: 'сіздің жалпы рейтингтегі позицияңыз',
    points: 'жалпы балл',
    subject_points: '{{point}} балл',
    best_subthemes: 'Сіз жақсы білетін тақырыптар',
    worst_subthemes: 'Сізге дайындалу керек тақырыптар',
    work_on_test: 'Қатемен жұмыс',
  },
  test,
}

export default locale
