import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  plan_finish_congrats: {
    title: 'Курс успешно завершен!',
    subtitle: 'Поздравляем вас – вы справились замечательно.<br>Вы успешно завершили свой курс.',
  },
}

export default locale
