import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  homeworks: 'Домашние задания',
  show_all: 'Посмотреть все',
  homework_order: 'Урок №{{order}}',
  tasks_cnt: 'Кол-во заданий:',
  no_homeworks: 'Нет домашних заданий',
  no_homework_questions: 'Все вопросы по этому заданию еще не добавлены. Наша команда уже работает над ними.',
  test: {
    title: 'Страница теста',
    quit_test: 'Выйти с теста',
    question_index: '{{index}} из {{count}}',
    prev_question: 'Предыдущий вопрос',
    next_question: 'Следующий вопрос',
    select_answer: 'Выберите ответ',
    finish: 'Завершить тест',
    submit_ans: 'Проверить ответ',
    use_hint: 'Использовать подсказку',
    hint_no: 'Подсказка #{{no}}',
  },
  messages: {
    test_finished: 'Тест завершен',
    test_result: 'Поздравляем! Вы завершили тест. Ваш результат – {{points}}/{{total}}',
    test_not_available: 'Домашнее задание пока недоступно',
    test_available_time: 'Начало домашнего задания в {{time}}',
  },
}

export default locale
