import {LocaleDataModel} from '@web/core/models/local/locale.model'

const auth: LocaleDataModel = {
  login: {
    title: 'Кіру',
    phone: 'Телефон',
    password: 'Құпия сөз',
    sign_in: 'Кіру',
    forget_password: 'Құпия сөзді ұмыттыңыз ба?',
    register: 'Тіркелу',
  },
  register: {
    title: 'Тіркелу',
    phone: 'Телефон',
    full_name: 'Аты-жөні',
    region: 'Аймақ',
    password: 'Құпия сөзді ойлап табыңыз',
    confirm_password: 'Құпия сөзді қайта теріңіз',
    create_account: 'Тіркелгі жасау',
    passwords_must_be_equal: 'Құпия сөздер сәйкес болуы тиіс.',
  },
  code: {
    title: 'Нөмірді растау',
    resend_code_after: 'Кодты қайтадан жіберуге болатын уақыт',
    resend_code: 'Кодты қайта жіберу',
  },
  password_reset: {
    title: 'Құпия сөзді жаңарту',
    password: 'Құпия сөзді ойлап табыңыз',
    confirm_password: 'Құпия сөзді қайта теріңіз',
    sign_in: 'Кіру',
  },
}

export default auth
