import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Сынақ парақшасы',
  heading: 'Сұрақ мәтіні',
  quit_test: 'Сынақтан шығу',
  subject_list: 'Пәндер тізімі',
  question_index: '{{index}} / {{count}}',
  prev_question: 'Алдыңғы сұрақ',
  next_question: 'Келесі сұрақ',
  finish: 'Сынақты аяқтау',
  submit_ans: 'Жауап беру',
  use_hint: 'Анықтаманы пайдалану',
  hint_no: 'Анықтама #{{no}}',
  continue: 'Жалғастыру',
  finished: 'Аяқталды',
  start: 'Бастау',

  did_you_find_errors: 'Қате таптыңыз ба?',
  support_request: {
    title: 'Қате түрін таңдаңыз',
    description_placeholder: 'Егер сіздің қатеңіз тізімде жоқ болса, қатеңізді осы жерге толығырақ жазып беруіңізді сұраймыз...',
    cancel: 'Болдырмау',
    submit: 'Жіберу',
    success_modal: {
      title: 'Көмегіңізге рахмет!',
      subtitle: 'Қателерімізді таба отырып, сіз біздің дамуымызға көп көмектесесіз!',
      close: 'Жабу',
    },
  },
}

export default locale
