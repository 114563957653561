import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  plan_finish_congrats: {
    title: 'Курс сәтті аяқталды!',
    subtitle: 'Құттықтаймыз - өте жақсы жұмыс жасадыңыз.<br>Сіз курсты сәтті аяқтадыңыз.',
  },
}

export default locale
