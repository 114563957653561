import {LocaleDataModel} from '@web/core/models/local/locale.model'

const groups: LocaleDataModel = {
  list: {
    title: 'Мамандықтар',
    programs: 'Мамандықтар',
    employment_rate: 'Жұмысқа орналасу',
    probability_count: 'Мүмкіндікті анықтау',
    reset_subjects: 'Пәндерді тазарту',
    first_subject: 'Бірінші пән',
    second_subject: 'Екінші пән',
    rural: 'Ауыл квотасы',
    your_probable_grade: 'Сіздің ҰБТ-дан алатын баллыңыз',
    see_chances: 'Мүмкіндіктерді көру',
  },
  view: {
    title: 'Мамандықтар',
    subjects: 'Пәндер',
    description: 'Сипаттама',
    universities: 'Университеттер',
    edu: 'Білім беру',
    salary: 'Жалақы',
    employment_rate: 'Сұраныс',
    grant_cnt: 'Грант саны',
    score_table: 'Грантқа шекті ұпай',
    score_table_year: 'Жыл',
    score_table_general: 'Жалпы',
    score_table_rural: 'Ауылдық',
  },
  search_choices: {
    title: {
      1: 'Мамандықтар',
      2: 'Таңдаулар',
    },
    by_groups: 'Мамандық арқылы',
    by_areas: 'Сала арқылы',
    select_group: 'Мамандықты таңдау',
    select_area: 'Саланы таңдау',
    see_result: 'Нәтижені көру',
    empty_groups_list: 'Сәйкес мамандықтар табылмады',
    empty_areas_list: 'Сәйкес салалар табылмады',
  },
  search_result: {
    title: {
      1: 'Мамандықтар',
      2: 'Ықтималдық',
    },
    total_probability: 'Мемлекеттік грант алудың жалпы ықтималдығы',
    select_another: 'Басқасын таңдау',
    not_selected: 'Таңдалмады',
    close: 'Жабу',
    select_group: 'Мамандықты таңдау',
    select_university: 'Университетті таңдау',
    select_grant_type: 'Грант түрін таңдау',
  },
}

export default groups
