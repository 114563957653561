import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'

import {ApiService} from '@web/core/services/api/api.service'
import {SKIP_LOADER_SHOWING_PARAMS} from '@web/core/utils/params'
import {PostModel} from '@web/core/models/api/core/post.model'

@Injectable({
  providedIn: 'root',
})
export class PostsService extends ApiService {

  constructor(
    protected http: HttpClient,
  ) {
    super('posts')
  }

  fetchRubrics(): Observable<any[]> {
    return this.http.get<any[]>(this.getUrl('rubrics'), {params: SKIP_LOADER_SHOWING_PARAMS})
  }

  get(id: number): Observable<PostModel> {
    return this.http.get<PostModel>(this.getUrl(id))
  }

  putLike(id: number): Observable<any> {
    return this.http.post<any>(this.getUrl(`${id}/like`), null, {params: SKIP_LOADER_SHOWING_PARAMS})
  }

  deleteLike(id: number): Observable<any> {
    return this.http.delete<any>(this.getUrl(`${id}/like`), {params: SKIP_LOADER_SHOWING_PARAMS})
  }

  putBookmark(id: number): Observable<any> {
    return this.http.post<any>(this.getUrl(`bookmarks`), {post: id}, {params: SKIP_LOADER_SHOWING_PARAMS})
  }

  deleteBookmark(id: number): Observable<any> {
    return this.http.delete<any>(this.getUrl(`bookmarks/${id}`), {params: SKIP_LOADER_SHOWING_PARAMS})
  }//
}
