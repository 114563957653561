export const getOnlyText = (text: string): string => {
  try {
    return text
      .replace(/[^а-яА-ЯәіңғүұқөһӘІҢҒҮҰҚӨҺ ]+/g, '')
      .trim()
  } catch (e) {
  }
  return text
}

export const getShortText = (text: string): string => {
  try {
    return getOnlyText(text)
      .split(' ')
      .map(word => word.substr(0, 3).trim())
      .join('. ')
  } catch (e) {
  }
  return text
}
