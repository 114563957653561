import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  page_title: 'Тренажер',
  theme_list: 'Темы',
  empty_theme_list: 'По этому предмету еще нет вопросов. Наша команда уже работает над ними.',
  test_page: 'Страница теста',
  subject_list: 'Список предметов',
  subtheme_progress: 'Прогресс: {{progress}}',
  subtheme_progress_done: 'Выполнено',
  subtheme_completed: 'Тест завершен!',
  use_solution: 'Использовать подсказку',
  solution_header: 'Подсказка #{{id}}',
  submit_question: 'Проверить ответ',
  next_question: 'Следующий вопрос',
  select_answer: 'Выберите ответ',
  restart: {
    label: 'Начать заново',
    confirm: 'Вы хотите начать заново?',
    cancel: 'Отменить',
  },
  main: {
    title: 'Тренажер',
    banner: {
      title: 'Проходи тесты и закрепляй знания',
      detail: 'Тесты разных уровней сложности',
      btn: 'Подробнее',
    },
    info_modal: {
      title: 'Что такое<br>«Тренажер»?',
      message: 'Тренажер – это место, где вы можете прокчать свои знания по определенным предметам и темам. Выполняйте тестовые задания на любые темы по 5-ти основным предметам и закрепяйте пройденный материал. Удачи!',
    },
    results: {
      title: 'Результат недели',
      empty: 'Нет данных',
      today_correct_answers: 'Сегодня: {{count}} правильных ответа(-ов)',
    },
    subjects: {
      title: 'Список предметов',
    },
  },
  filter: {
    btn_text: 'Выберите тему',
    header: 'Выбор темы',
    footer: {
      cancel: 'Отмена',
      submit: 'Выбрать тему',
    },
  },
}

export default locale

