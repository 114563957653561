import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'

import {ApiService} from '@web/core/services/api/api.service'
import {SKIP_LOADER_SHOWING_PARAMS} from '@web/core/utils/params'
import {DictionariesModel} from '@web/core/models/api/core/dictionaries.model'

@Injectable({
  providedIn: 'root',
})
export class DictionariesService extends ApiService {

  constructor(
    protected http: HttpClient,
  ) {
    super('dictionaries')
  }

  fetch(): Observable<DictionariesModel> {
    return this.http.get<DictionariesModel>(this.getUrl(), {params: SKIP_LOADER_SHOWING_PARAMS})
  }
}
