import {LocaleDataModel} from '@web/core/models/local/locale.model'

const posts: LocaleDataModel = {
  list: {
    main: 'Барлығы',
    popular: 'Танымал',
    bookmarks: 'Сақталғандар',
    empty_list: 'Мына жерге өзіңізге ұнаған посттарды сақтай аласыз.',
  },
  view: {
    back_to_main: 'Басқы бетке',
  },
}

export default posts
