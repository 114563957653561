import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Уроки',
  today: 'Сегодня',
  lesson_count: 'Уроки: {{length}}',
  lesson_broadcast: 'Трансляция урока',
  all_schedule: 'Все расписания',
  show_all: 'Посмотреть все',
  lesson_page: 'Страница урока',
  no_lessons: 'Сегодня нет уроков',
  no_record_url: 'Запись эфира еще не доступна',
  empty_list: 'Список пуст',
  lesson_order: 'Тема',
  lesson_order_name: 'Сабақ №{{order}}. {{name}}',
  lesson_empty: 'Дополнительные материалы по этому предмету еще не добавлены. Наша команда уже работает над ними.',
  lesson_starts_at: 'Начало в {{time}}',
  lesson_started_at: 'Началось в {{time}}',
  lesson_finished: 'Завершен',
  lesson_started: 'Урок уже начался! Пройдите по ссылке который отправили вам кураторы в группах',
  open_broadcast_url: 'Перейти на трансляцию',
  errors: {
    lesson_not_started: 'Урок еще не начался',
    lesson_not_started_msg: 'Начало урока в {{time}}',
  },
  tabs: {
    broadcast: 'Прямой эфир',
    materials: 'Доп.материалы',
  },
  homework: {
    title: 'Домашнее задание',
  },
  description: {
    read_more: 'Читать все',
    read_less: 'Читать меньше',
  },
  broadcast: {
    comment_textarea: 'Введите текст комментария',
  },
  lock: {
    header: 'Предмет не оплачен',
    content: `Обучение за следующий период <span class="nowrap c-red">не оплачено.</span> Обратитесь по номеру <b class="nowrap">+7 747 650 37 22</b> для уточнения деталей.`,
  },
}

export default locale
