import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Разделы обучения',
  lesson: {
    title: 'Мои уроки',
    page_header: 'Предметы',
    detail: 'Материалы онлайн-курса',
    expire: 'Есть неоплаченный предмет',
  },
  trainer: {
    title: 'Тренажер',
    detail: 'Тренируйся выполняя тесты',
  },
  ent_questions: {
    title: 'Вопросы ЕНТ 2022',
    detail: 'Отвечай на вопросы ЕНТ',
  },
  weekly_test: {
    title: 'Еженедельный тест',
    detail: 'Состязайся с другими студентами',
  },
  trial_test: {
    title: 'Пробный тест онлайн',
    start: 'Начало',
    end: 'Завершение',
    take_test: 'Пройти тест',
    my_result: 'Мой результат',
  },
}

export default locale
