import {LocaleDataModel} from '@web/core/models/local/locale.model'

const universities: LocaleDataModel = {
  local_unis: 'Казахстан',
  foreign_unis: 'Другие страны',
  list: {
    title: 'ВУЗы',
    universities_count: 'Университетов',
    programs_count: 'Специальности',
    search_hint: 'Поиск по названию...',
    select_city: 'Выберите город',
    all_cities: 'Все города',
  },
  view: {
    title: 'ВУЗы',
    grant_cnt: 'Кол-во грантов',
    min_stat_grade: 'Мин. балл на грант',
    description: 'Описание',
    programs: 'Специальности',
    about_university: 'Об университете',
    average_price: 'Средняя цена',
    status: 'Статус',
    dormitory: 'Общежитие',
    military_faculty: 'Военная кафедра',
    get_consultee: 'Получить консультацию',
    contacts: 'Контакты',
    website: 'Веб-сайт',
    gallery: 'Фотогалерея',
  },
  foreign: {
    foreign_countries_count: 'Страны',
    university_count: 'Вузы',
    view: {
      title: 'ВУЗы',
      grant_cnt: 'Кол-во грантов',
      min_stat_grade: 'Мин. балл на грант',
      description: 'Описание',
      programs: 'Специальности',
      about_university: 'Об университете',
      average_price: 'Средняя цена',
      status: 'Статус',
      dormitory: 'Общежитие',
      military_faculty: 'Военная кафедра',
      contacts: 'Контакты',
      website: 'Веб-сайт',
      gallery: 'Фотогалерея',
      advantage: 'Преимущества',
      requirements: 'Требование',
      city_images: 'Фото города',
      city_price: 'Цена проживания',
      city_price_amount: '{{price}}/в месяц',
      city_security: 'Безопастность',
      city_comfort: 'Комфортность',
      apply: 'ХОЧУ ПОСТУПИТЬ',
      apply_form: {
        header: 'Хотите поступить в этот университет?',
        text: 'Мы ответим на все ваши вопросы. Чтобы получить бесплатную консультацию, нажмите кнопку ниже.',
        hint: 'Оставьте заявку и получите первую <b>бесплатную</b> консультацию!',
        confirm: 'Хочу поступить',
        reject: 'Позже',
      },
    },
  },
  consultee: {
    msg_header: 'Спасибо!',
    msg_text_foreign: 'Мы обязательно вам позвоним, не пропустите наш звонок!',
    msg_text_local: 'Представители Университета позвонят вам ближайшее время.',
    msg_close: 'Отлично',
  },
}

export default universities
