import {LocaleDataModel} from '@web/core/models/local/locale.model'

const comments: LocaleDataModel = {
  show_more: 'Тағы көрсету',
  was_it_useful: 'Пайдалы болды ма?',
  to_reply: 'Пікір жазу',
  view_replies: 'Жауаптарды көру',
  reply_for: 'Жауап жазу:',
  input_hint: 'Сіздің мұғалімге қоятын сұрағыңыз...',
  send: 'Жіберу',
  empty_list: 'Сұрақтар жоқ. Сұрақты бірінші болып қойыңыз.',
  too_large_file: 'Әр файлдың көлемі 1 МБ-тан аспауы керек.',
  too_many_files: 'Ең көбі 5 файлды жүктей аласыз.',
}

export default comments
