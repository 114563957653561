import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Предметы',
  lesson_cnt: 'Количество уроков: {{cnt}}',
  lesson_finished_cnt: 'Завершенные: {{cnt}}',
  expire: 'Предмет не оплачен',
}

export default locale
