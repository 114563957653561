import {Injectable} from '@angular/core'
import {Action, Selector, State, StateContext, Store} from '@ngxs/store'
import {Navigate} from '@ngxs/router-plugin'
import moment, {Moment} from 'moment'

import {Login, Logout, RemoveToken, UpdateToken} from './actions'

import {IdentityService} from '@web/core/services/api/identity.service'
import {TokenModel} from '@web/core/models/api/core/token.model'

interface AuthStateModel {
  token: TokenModel;
  accessTokenExpireDate?: Moment;
}

const defaults = {
  token: null,
  accessTokenExpireDate: null,
}

@State<AuthStateModel>({
  name: 'auth',
  defaults,
})
@Injectable()
export class AuthState {

  @Selector()
  static token({token}: AuthStateModel): TokenModel {
    return token
  }

  @Selector()
  static accessTokenExpireDate({accessTokenExpireDate}: AuthStateModel): Moment {
    return accessTokenExpireDate
  }

  constructor(
    private store: Store,
    private identityService: IdentityService,
  ) {
  }

  @Action(Login)
  Login({patchState}: StateContext<AuthStateModel>, {token}: Login) {
    const decodedAccessToken = JSON.parse(window.atob(token.access.split('.')[1]))

    return patchState({
      token, accessTokenExpireDate: moment.unix(decodedAccessToken.exp),
    })
  }

  @Action(UpdateToken)
  UpdateToken({patchState}: StateContext<AuthStateModel>, {token}: UpdateToken) {
    const decodedAccessToken = JSON.parse(window.atob(token.access.split('.')[1]))

    return patchState({
      token, accessTokenExpireDate: moment.unix(decodedAccessToken.exp),
    })
  }

  @Action(RemoveToken)
  RemoveToken({patchState}: StateContext<AuthStateModel>) {
    return patchState(defaults)
  }

  @Action(Logout)
  Logout({patchState}: StateContext<AuthStateModel>) {
    return this.identityService.logout()
      .toPromise()
      .finally(() => {
        patchState(defaults)
        this.store.dispatch(new Navigate(['/auth']))
      })
  }
}
