import {LangType} from '@web/core/types/api/lang.type'

export class Init {
  static readonly type = '[App] Init'
}

export class UpdateLang {
  static readonly type = '[App] UpdateLang'

  constructor(public lang: LangType) {
  }
}

export class UpdateStatic {
  static readonly type = '[App] UpdateStatic'
}

export class FetchLocalesBasedStatic {
  static readonly type = '[App] FetchLocalesBasedStatic'
}
