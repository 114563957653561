import {LocaleDataModel} from '@web/core/models/local/locale.model'

const general: LocaleDataModel = {
  back: 'Артқа',
  next: 'Келесі',
  cancel: 'Болдырмау',
  save: 'Сақтау',
  subtitle_2: 'Қосымшаны жүктеп алыңыз',
  title_2: 'Егер сіз ұялы телефоннан Joo.kz қызметтерін пайдаланғыңыз келсе, мобильді қосымшаны жүктеп алыңыз!',
  title_3: 'Бұл сайтты тек компьютерден көре аласыз!',
  days: 'күн',
  hours: 'сағ',
  minutes: 'мин',
  seconds: 'сек',
  today: 'Бүгін',
  back_to_main: 'Басқы бетке оралу',
  empty_list: 'Тізім бос',
}

export default general
