import {LocaleDataModel} from '@web/core/models/local/locale.model'

const posts: LocaleDataModel = {
  list: {
    main: 'Главная',
    popular: 'Популярное',
    bookmarks: 'Сохраненные',
    empty_list: 'Здесь вы можете сохранять посты которые вам понравились.',
  },
  view: {
    back_to_main: 'На главную',
  },
}

export default posts
