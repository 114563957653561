import {LocaleDataModel} from '@web/core/models/local/locale.model'
import test from './test/ru'

const locale: LocaleDataModel = {
  title: 'Еженедельные тесты',
  start_test: 'Начать тест',
  continue_test: 'Продолжить тест',
  my_test_results: 'Мой результат',
  test_results: 'Общий рейтинг',
  main: {
    title: 'Еженедельный тест',
    my_tests: 'Мои тесты',
    description: 'Сдавайте тест каждую неделю и смотрите свои результаты в рейтинге студентов в конце недели',
    hint: 'Вы можете сдавать тест раз в неделю',
    not_found: 'Еженедельный тест еще не готов. Наша команда уже работает над ними.',
    not_passed: {
      title: 'Вы не сдавали пробный тест',
      countdown: 'След.тест начнется через – ',
    },
    not_started: {
      start: 'Начать',
      countdown: 'Вы все еще можете пройти тест:', // TODO
    },
    started: {
      continue: 'Продолжить',
      countdown: 'Тест доступен еще – ', // TODO
    },
    finished: {
      see_results: 'Мои результаты',
      test_history: 'История тестов',
      countdown: 'Общий рейтинг выйдет через:', // TODO
    },
    has_results: {
      title: 'Итоги недели!',
      list_header: 'Весь рейтинг',
      place: 'Место',
      points: '{{points}} баллов • {{time}} минут',
      rating_points: '{{place}} место • {{points}} баллов',
    },
    start_modal: {
      title: 'Начать тест',
      message: 'На весь тест дается 4 часа. За это время вы должны завершить тест. Вы можете выйти из теста и снова войти в систему в любое время в течение этих 4 часов.',
      cancel_text: 'Отмена',
      confirm_text: 'Начать тест',
    },
    rating_placeholder: 'Сдавайте еженедельный тест, чтобы вы могли видеть рейтинг учеников на основе результатов еженедельного теста',
    empty_rating: 'Список пуст',
    all_subjects: 'Все предметы',
    filter: {
      select_first_subject: 'Выберите первый предмет',
      select_second_subject: 'Выберите второй предмет',
    },
  },
  configure: {
    title: 'Выберите предметы',
    select_subject: 'Выберите предмет',
    leave_empty: 'Не выбирать',
    select_language: 'Выберите язык',
    go_back: 'На главную',
    start_test: 'Начать тест',
    required_subject: 'Обязательный предмет',
    cannot_select_subject_modal: {
      title: 'Внимание',
      message: 'Сначала выберите первый предмет.',
    },
  },
  history: {
    title: 'Мои тесты',
    empty_list: 'Здесь отображается список пройденных вами еженедельных тестов',
    points: '{{points}} балл',
    go_back: 'На главную',
    graph_header: 'График последних тестов',
  },
  subject: {
    title: 'Предметы',
    title_countdown: 'Предметы ({{countdown}})',
    go_back: 'На главную',
    finish_test: 'Завершить тест',
    finish_test_modal: {
      title: 'Завершить тест',
      message: 'Вы уверены, что хотите завершить тест? После завершения теста вы не сможете изменить свои ответы.',
      cancel_text: 'Отмена',
      confirm_text: 'Завершить тест',
    },
    quit: 'Выйти',
    back: 'Назад',
    next: 'Следующий',
    waiting_test_generation: 'Подождите, Ваш тест генерируется...',
  },
  results: {
    result_page: 'Страница результата',
    weekly_overall: 'Итоги недели',
    title: 'Ваш результат',
    go_back: 'На главную',
    see_errors: 'Работа с ошибками',
    your_place: 'Ваша позиция:',
    place: '{{place}} место',
    in_overall_rating: 'в общем рейтинге',
    analytics_best_themes: 'Темы которые<br>вы хорошо знаете',
    analytics_worst_themes: 'Темы которые<br>нужно изучить',
    want_more_edu: 'Хочу улучшить результат',
  },
  analysis: {
    title: 'Мои результаты',
    place: 'место в общем рейтинге',
    points: 'всего баллов',
    subject_points: '{{point}} баллов',
    best_subthemes: 'Темы которые нужно изучить',
    worst_subthemes: 'Темы которые вы хорошо знаете',
    work_on_test: 'Работа над ошибками',
  },
  rating: {
    header: 'Рейтинг недели',
    filter: 'Все предметы',
    points: '{{point}} балла',
    filter_header: 'Выбор предмета',
    filter_submit: 'Ок',
    filter_cancel: 'Назад',
    total_rating: 'Общий рейтинг',
    my_friends: 'Мои друзья',
    app_banner: 'Хотите посмотреть кто еще сдал тест в вашем классе?<br>Скачайте приложение Joo.kz и поделитесь контактами',
  },
  test,
}

export default locale
