import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Профильные предметы',
  select: 'Выберите профильные предметы',
  select_first_subject: 'Выбрать предмет',
  select_second_subject: 'Выбрать предмет',
  important_hint: '* Ученик должен окончить школу 2022 году',
  submit: 'Отправить заявку',
  subject_select_modal: {
    first_subject: 'Первый предмет',
    second_subject: 'Второй предмет',
  },
  success_request_modal: {
    title: 'Ваша заявка принята',
    message: 'Спасибо, что вы с нами! Ожидайте звонка.',
    close: 'Закрыть',
  },
  package_1: {
    name: 'Стандарт пакеті',
    discount: '–22%',
    price: '124 800 ₸',
    price_prev: '160 000₸',
    feature_1: 'Стандарт пакетіне алдыңғы бетте айтылған барлық артықшылықтар кіреді',
  },
  package_2: {
    name: 'Премиум пакеті',
    discount: '–20%',
    price: '200 000 ₸',
    price_prev: '250 000₸',
    feature_1: 'Стандарт пакетіндегі барлық артықшылық кіреді',
    feature_2: 'Арнайы психолог маманымен ҰБТ-ға психологиялық көмек',
    feature_3: 'ҰБТ-дан кейін профориентатор маман жеке хабарласып, 4 мамандық пен 4 университет таңдауға көмек береді',
  },
}

export default locale
