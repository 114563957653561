import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  title: 'Бейіндік пәндер',
  select: 'Бейіндік пәндерді таңдаңыз',
  select_first_subject: 'Пәнді таңдау',
  select_second_subject: 'Пәнді таңдау',
  important_hint: '* Оқушы мектепті 2023 жылы бітіруі керек',
  submit: 'Өтініш жіберу',
  subject_select_modal: {
    first_subject: 'Бірінші пән',
    second_subject: 'Екінші пән',
  },
  success_request_modal: {
    title: 'Сіздің өтінішіңіз қабылданды',
    message: 'Бізбен бірге болғаныңызға рахмет! Қоңырауды күтіңіз.',
    close: 'Жабу',
  },
  package_1: {
    name: 'Стандарт пакеті',
    price: '124 800 ₸',
    price_prev: '160 000₸',
    feature_1: 'Стандарт пакетіне алдыңғы бетте айтылған барлық артықшылықтар кіреді',
  },
  package_2: {
    name: 'Премиум пакеті',
    price: '200 000 ₸',
    price_prev: '250 000₸',
    feature_1: 'Стандарт пакетіндегі барлық артықшылық кіреді',
    feature_2: 'Арнайы психолог маманымен ҰБТ-ға психологиялық көмек',
    feature_3: 'ҰБТ-дан кейін профориентатор маман жеке хабарласып, 4 мамандық пен 4 университет таңдауға көмек береді',
  },
}

export default locale
