import {LocaleDataModel} from '@web/core/models/local/locale.model'

const comments: LocaleDataModel = {
  show_more: 'Показать еще',
  was_it_useful: 'Было полезно?',
  to_reply: 'Ответить',
  view_replies: 'Смотреть ответы',
  reply_for: 'Ответ для',
  input_hint: 'Ваш вопрос учителю...',
  send: 'Отправить',
  empty_list: 'Нет вопросов. Будьте первым, кто задаст вопрос.',
  too_large_file: 'Размер каждого файла не должен превышать 1 Мб.',
  too_many_files: 'Можно загрузить не более 5 файлов.',
}

export default comments
