import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {forkJoin, Observable} from 'rxjs'
import {map, shareReplay} from 'rxjs/operators'

import {env} from '@ui/env'

import {ApiService} from '@web/core/services/api/api.service'
import {ListModel} from '@web/core/models/api/core/list.model'
import {MainSubjectModel} from '@web/core/models/api/core/mainSubjectModel'
import {SubjectPairsModel} from '@web/core/models/api/core/subject-pairs.model'
import {SubjectPairsLocalModel} from '@web/core/models/local/subject-pairs.local.model'
import {SubjectLocalModel} from '@web/core/models/local/subject.local.model'
import {SKIP_LOADER_SHOWING_PARAM_KEY} from '@web/core/utils/params'
import {getOnlyText} from '@web/core/utils/string'

const params: any = {page_size: 50, [SKIP_LOADER_SHOWING_PARAM_KEY]: true}

@Injectable({
  providedIn: 'root',
})
export class SubjectsService extends ApiService {

  subjects$: Observable<any[]> = this.http
    .get<ListModel<any>>(`${env.apiHost}/api/v1/matrix/subjects/`, {params})
    .pipe(
      shareReplay(1),
      map(list => {
        const subjects = list.results
        subjects.forEach(subject => {
          subject.name_kk = getOnlyText(subject.name_kk)
          subject.name_ru = getOnlyText(subject.name_ru)
        })
        return subjects
      }),
    )

  constructor(
    protected http: HttpClient,
  ) {
    super('subjects')
  }

  fetchFromMain(): Observable<SubjectLocalModel[]> {
    const params: any = {page_size: 50, [SKIP_LOADER_SHOWING_PARAM_KEY]: true}

    return forkJoin([
      this.http.get<ListModel<MainSubjectModel>>(this.getUrl(), {params}),
      this.http.get<ListModel<SubjectPairsModel>>(this.getUrl('pairs'), {params}),
    ]).pipe(
      map(([subjects, pairs]) => {
        const subjectMap: { [subjectId: number]: SubjectPairsLocalModel[] } = {}

        pairs.results.forEach(pair => {
          if (!subjectMap[pair.subject_1]) {
            subjectMap[pair.subject_1] = []
          }

          if (!subjectMap[pair.subject_2]) {
            subjectMap[pair.subject_2] = []
          }

          if (!subjectMap[pair.subject_1].find(({subjectId}) => subjectId === pair.subject_2)) {
            subjectMap[pair.subject_1].push({
              subjectId: pair.subject_2,
              minGrade: pair.min_grade,
              maxGrade: pair.max_grade,
              languages: pair.languages,
              choicesNum: pair.choices_number,
            })
          }

          if (!subjectMap[pair.subject_2].find(p => p.subjectId === pair.subject_1)) {
            subjectMap[pair.subject_2].push({
              subjectId: pair.subject_1,
              minGrade: pair.min_grade,
              maxGrade: pair.max_grade,
              languages: pair.languages,
              choicesNum: pair.choices_number,
            })
          }
        })

        const customSubjects: SubjectLocalModel[] = subjects.results.map(subject => {
          return {
            ...subject,
            pairs: subjectMap[subject.id],
          }
        })

        return customSubjects
      }),
    )
  }
}
