import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'

import {ApiService} from '@web/core/services/api/api.service'
import {ListModel} from '@web/core/models/api/core/list.model'
import {map} from 'rxjs/operators'
import {AreaModel} from '@web/core/models/api/core/area.model'
import {SKIP_LOADER_SHOWING_PARAM_KEY} from '@web/core/utils/params'

@Injectable({
  providedIn: 'root',
})
export class AreasService extends ApiService {

  constructor(
    protected http: HttpClient,
  ) {
    super('areas')
  }

  fetch(): Observable<any[]> {
    const params: any = {page_size: 50, [SKIP_LOADER_SHOWING_PARAM_KEY]: true}
    return this.http.get<ListModel<any>>(this.getUrl(), {params}).pipe(map(({results}) => results))
  }

  get(id: number): Observable<AreaModel> {
    return this.http.get<AreaModel>(this.getUrl(id))
  }
}
