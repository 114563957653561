import {LocaleDataModel} from '@web/core/models/local/locale.model'

const account_management: LocaleDataModel = {
  title: 'Удаление аккаунта',
  hint: 'Вы уверены, что хотите удалить свой аккаунт?<br><br>После удаления аккаунта все данные, связанные с аккаунтом, будут потеряны. Вы не сможете пользоваться приложением Joo.kz<br><br>Введите свой номер телефона и пароль, чтобы удалить свой аккаунт.',
  phone: 'Телефон',
  password: 'Пароль',
  delete_account: 'Удалить аккаунт',
  return_to_main: 'Вернуться на главную страницу',
  result_modal: {
    success: {
      title: 'Аккаунт удален',
      message: 'Все данные связанные с вашим аккаунтом Joo.kz – успешно удалены',
    },
    error: {
      title: 'Неправильные данные',
      message: 'Вы указали неправильный номер телефона или пароль',
    },
  },
}

export default account_management
