import {LocaleDataModel} from '@web/core/models/local/locale.model'

const locale: LocaleDataModel = {
  btn: 'Қате таптыңыз ба?',
  title: 'Қате түрін таңдаңыз',
  description_placeholder: 'Егер сіздің қатеңіз тізімде жоқ болса, қатеңізді осы жерге толығырақ жазып беруіңізді сұраймыз...',
  cancel: 'Болдырмау',
  submit: 'Жіберу',
  success_modal: {
    title: 'Көмегіңізге рахмет!',
    subtitle: 'Қателерімізді таба отырып, сіз біздің дамуымызға көп көмектесесіз!',
    close: 'Жабу',
  },
}

export default locale
