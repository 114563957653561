import {LocaleDataModel} from '@web/core/models/local/locale.model'

const account_management: LocaleDataModel = {
  title: 'Аккаунтты жою',
  hint: 'Аккаунтты шынымен жойғыңыз келе ме?<br><br>Аккаунтты жойылғаннан кейін, аккаунтпен байланысты барлық деректер жойылады. Сіз Joo.kz қосымшасын пайдалана алмайсыз<br><br>Аккаунтты жою үшін телефон нөміріңізді және құпия сөзді енгізіңіз.',
  phone: 'Телефон',
  password: 'Құпия сөз',
  delete_account: 'Аккаунтты жою',
  return_to_main: 'Басқы бетке оралу',
  result_modal: {
    success: {
      title: 'Аккаунт жойылды',
      message: 'Joo.kz-те тіркеген барлық деректер сәтті жойылды',
    },
    error: {
      title: 'Қате деректер',
      message: 'Сіз телефон нөміріңізді немесе құпия сөзді қате енгіздіңіз',
    },
  },
}

export default account_management
